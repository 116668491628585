.save-controls {
    position: absolute;
    top: 20px;
    /* Distancia desde el borde superior */
    left: 50%;
    /* Centrar horizontalmente */
    transform: translateX(-50%);
    z-index: 1000;
    display: flex;
}

.save-button {
    margin-bottom: 10px;
    /* Espacio entre los botones */
}


.save-button__button {
    background-color: #4CAF50;
    /* Green */
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
}

.save-button__button--regadio {
    background-color: #007BFF;
}

.save-button__button--cultivo {
    background-color: #21a032;
}

.save-button__button--delete {
    background-color: #cc0000;
}

.save-button__button--tratregadio {
    background-color: #dbcd02;
}

.save-button__button--tratcultivo {
    background-color: #bb6a00;
}

.save-button__button:hover {
    filter: brightness(80%);
}

.striped-pattern {
    fill: repeating-linear-gradient(45deg, yellow 0px, yellow 2px, transparent 2px, transparent 4px); /* Patrón rayado */
  }